@import '../../variables';

.market-section {
  min-height: 100vh;
  padding: 4rem 2rem;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $background-light;
  position: relative;

  .container {
    max-width: 1200px;
    width: 100%;
  }

  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: $background-dark;
    text-align: center;
  }

  .description {
    font-size: 1.25rem;
    color: $secondary-color;
    margin-bottom: 2rem;
    text-align: center;
  }

  .content-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    padding: 2rem;

    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
    }

    .text-content {
      p {
        font-size: 1.125rem;
        line-height: 1.6;
        color: $secondary-color;
        margin-bottom: 1.5rem;
        text-align: left;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .chart-container {
      margin-top: 20px;
      width: 100%;
      height: 400px;
      background: $background-dark;
      border-radius: 8px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      overflow: hidden;

      @media (max-width: 1024px) {
        height: 300px;
      }
    }
  }
}

// Responsive adjustments
@media (max-width: 1024px) {
  .market-section {
    padding: 2rem 1rem;

    h1 { font-size: 2rem; }
    .description { font-size: 1rem; }

    .content-grid { padding: 1rem; }
  }
}

@media (max-width: 768px) {
  .market-section {
    padding: 1rem;

    h1 { font-size: 1.5rem; }
    .description { font-size: 0.875rem; }

    .content-grid {
      .text-content p { font-size: 1rem; }
      .chart-container { height: 250px; }
    }
  }
}