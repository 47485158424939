$primary-color: #4a90e2;
$secondary-color: #6b7280;
$background-light: #f7fafc;
$background-dark: #2d3748;
$accent-color: #3c70e7;
$white: #ffffff;
$accent-green: #2ecc71;
$accent-yellow: #ffeb3b;
$font-family: 'Poppins', sans-serif;
$transition: all 0.3s ease;
$background-gray: #f5f6f7; // Light gray from the image
$white: #ffffff;
$accent-blue: #3498db;
$background-blue: #3498db;