@import '../../variables';

.roadmap-section {
  min-height: 100vh;
  padding: 4rem 2rem;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $background-gray;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  .container {
    max-width: 1200px;
    width: 100%;
    padding: 2rem;
    background: $background-gray;
  }

  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: #2d3748;
    text-align: center;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: -0.5rem;
      left: 50%;
      transform: translateX(-50%);
      width: 50px;
      height: 2px;
      background: $accent-blue;
    }
  }

  .subheadline {
    font-size: 1.125rem;
    font-weight: 500;
    color: $secondary-color;
    text-align: center;
    margin-bottom: 2rem;
    text-transform: uppercase;
  }

  .timeline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 2rem 0;

    &::before {
      content: '';
      position: absolute;
      top: 40%;
      left: 0;
      right: 0;
      height: 2px;
      background: #e2e8f0;
      z-index: 1;
    }

    .timeline-item {
      position: relative;
      text-align: center;
      z-index: 2;
    //   width: 150px;

      .year {
        width: 100px;
        height: 100px;
        background: white;
        border: 2px solid #f0f0f0;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        font-weight: 600;
        color: #2d3748;
        margin: 0 auto 2rem;
        transition: $transition;
      }

      .milestone {
        font-size: 1.25rem;
        font-weight: 600;
        color: #2d3748;
        margin-bottom: 0.5rem;
      }

      p {
        font-size: 0.875rem;
        color: $secondary-color;
        line-height: 1.6;
        max-width: 120px;
        margin: 0 auto;
        min-height: 140px;
      }

      &.active .year {
        background: #1e47f3;
        color: $white;
        animation: pulse 1.5s infinite;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
      }

      @media (max-width: 1024px) {
        // width: 200px;

        .year { width: 70px; height: 70px; font-size: 0.875rem; margin: 0 0.5rem;}
        .milestone { font-size: 1rem; }
        p { font-size: 0.75rem; max-width: 100px; }
      }
    }
  }
}

// Responsive adjustments
@media (max-width: 1024px) {
  .roadmap-section {
    padding: 2rem 1rem;

    h1 { font-size: 2rem; }
    .subheadline { font-size: 1rem; }

    .timeline { flex-direction: column; gap: 2rem; }

    .timeline::before {
      top: 0;
      left: 63%;
      width: 0px;
      height: 100%;
      transform: translateX(-50%);
    }

    .timeline-item {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 1rem;

      .year { margin: 0; }
      .milestone, p { text-align: left; }
    }
  }
}

@media (max-width: 768px) {
  .roadmap-section {
    padding: 1rem;

    h1 { font-size: 1.5rem; }
    .subheadline { font-size: 0.875rem; }

    .timeline { gap: 1.5rem; }

    .timeline-item .year { width: 70px; height: 70px; font-size: 0.75rem; }
    .timeline-item .milestone { font-size: 0.875rem; }
    .timeline-item p { font-size: 0.7rem; }
  }
}

@media (min-width:360px) and (max-width: 400px) {
  .roadmap-section {
    padding: 2rem 1rem;

    h1 { font-size: 2rem; }
    .subheadline { font-size: 1rem; }

    .timeline { flex-direction: column; gap: 2rem; }

    .timeline::before {
      top: 0;
      left: 63%;
      width: 2px;
      height: 100%;
      transform: translateX(-50%);
    }

    .timeline-item {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 1rem;

      .year { margin: 0; }
      .milestone, p { text-align: left; }
    }
  }
}