@import '../../variables';

.antro-products-section {
  // min-height: 100vh;
  padding: 4rem 2rem;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $background-gray;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .container {
    max-width: 1200px;
    width: 100%;
    background: $background-gray;
    padding: 2rem;
  }

  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: $background-dark;
    text-align: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 1rem;
  }

  .tagline {
    font-size: 1.125rem;
    font-weight: 500;
    color: $secondary-color;
    text-align: center;
    margin-bottom: 2rem;
    text-transform: uppercase;
  }

  .antro-products-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    padding: 2rem 0;

    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    .antro-products-card {
      background: $white;
      padding: 1.5rem;
      border-radius: 5px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
      text-align: center;
      position: relative;
      transition: $transition;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
      }

      .sphere {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 20px;
        /* Adjust size as needed */
        height: 20px;
        /* Ensure it's a perfect circle */
        background-color: $accent-blue;
        /* Replace with your color */
        border-radius: 50%;
        z-index: 1;
      }

      h2 {
        font-size: 1.25rem;
        font-weight: 600;
        color: $background-dark;
        margin-bottom: 1rem;
      }

      p {
        font-size: 1rem;
        line-height: 1.6;
        color: $secondary-color;
        margin-bottom: 1rem;
      }

      .icon {
        display: flex;
        justify-content: center;
        margin-top: 1rem;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

// Responsive adjustments
@media (max-width: 1024px) {
  .antro-products-section {
    padding: 2rem 1rem;

    h1 {
      font-size: 2rem;
    }

    .tagline {
      font-size: 1rem;
    }

    .antro-products-grid {
      padding: 1rem 0;
    }
  }
}

@media (max-width: 768px) {
  .antro-products-section {
    padding: 1rem;

    h1 {
      font-size: 1.5rem;
    }

    .tagline {
      font-size: 0.875rem;
    }

    .antro-products-grid {
      .antro-products-card {
        padding: 1rem;

        h2 {
          font-size: 1rem;
        }

        p {
          font-size: 0.875rem;
        }
      }
    }
  }
}