@import '../../variables';
.overview-section {
  min-height: 100vh;
  padding: 4rem 2rem;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $white;
  position: relative;

  .container {
    // max-width: 1200px;
    width: 100%;
    background: $white;
    // border: 1px solid #ddd;
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  h1 {
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
    color: #2c3e50;
    text-align: center;
    padding-top: 1rem;
    text-align: left;
  }

  .content-grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 2rem;
    padding: 2rem;
    background: $white;

    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
    }

    .text-content {
      p {
        font-size: 1.125rem;
        line-height: 1.6;
        color: #7f8c8d;
        text-align: left;
        margin-bottom: 1.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .image-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;

      img {
        width: 100%;
        height: 150px;
        object-fit: cover;
        border-radius: 0.5rem;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
        transition: $transition;

        &:hover {
          transform: scale(1.05);
        }
      }

      @media (max-width: 1024px) {
        grid-template-columns: 1fr;
        img { height: 120px; }
      }
    }
  }

  .stats {
    display: flex;
    gap: 2rem;
    padding: 2rem;
    justify-content: center;
    background: #bcdcff;

    .stat-card {
      background: $white;
      padding: 1rem;
      border-radius: 50%;
      width: 120px;
      height: 120px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
      transition: $transition;

      .stat-value {
        font-size: 1.5rem;
        font-weight: 700;
        color: $accent-color;
        margin-bottom: 0.5rem;
      }

      p {
        font-size: 0.875rem;
        color: #7f8c8d;
        margin: 0;
      }

      &:hover {
        transform: scale(1.05);
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
  }

  .pillars {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    padding: 2rem;
    background: #3a60b7;
    color: $white;
    border-radius: 10px;
    margin-top: 20px;

    .pillar-card {
      background: rgba($white, 0.2);
      padding: 1rem;
      border-radius: 0.5rem;
      text-align: center;
      font-size: 1.125rem;
      font-weight: 600;
      transition: $transition;

      &:hover {
        background: rgba($white, 0.4);
        transform: scale(1.05);
      }
    }

    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
    }
  }

  // Sidebar and background pattern
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 100%;
    background: #7f8c8d;
    z-index: -1;
  }

  // Subtle background pattern
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"%3E%3Cpath fill="%23e9ecef" fill-opacity="0.1" d="M0,160L48,176C96,192,192,224,288,224C384,224,480,192,576,186.7C672,181,768,203,864,208C960,213,1056,203,1152,186.7C1248,171,1344,149,1392,138.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"%3E%3C/path%3E%3C/svg%3E') no-repeat center;
    background-size: cover;
    z-index: -2;
    opacity: 0.1;
  }
}

// Responsive adjustments
@media (max-width: 1024px) {
  .overview-section {
    .container { max-width: 100%; }

    h1 { font-size: 2rem; }

    .content-grid {
      padding: 1rem;
    }

    .stats, .pillars { padding: 1rem; }
  }
}

@media (max-width: 768px) {
  .overview-section {
    padding: 1rem;

    h1 { font-size: 1.5rem; }

    .content-grid {
      .text-content p { font-size: 1rem; }
      .image-content { display: none; } // Hide images on mobile for simplicity
    }

    .stats, .pillars { flex-direction: column; gap: 1rem; }
  }

  .overview-section::before {
    display: none; // Hide sidebar on mobile
  }
}