@import '../../variables';

.problem-section {
  // min-height: 100vh;
  padding: 4rem 2rem;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $background-light;
  perspective: 1000px; // For 3D effect
  position: relative;

  .container {
    // max-width: 1200px;
    width: 100%;
    text-align: center;
    display: flex;
  }

  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: $background-dark;
    text-align: left;
  }

  .description {
    font-size: 1.25rem;
    color: $secondary-color;
    margin-bottom: 2rem;
    text-align: left;
  }

  .sticky-notes {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    padding: 2rem;
    justify-content: center;

    .note {
      background: #ffe660;
      padding: 1.5rem;
      border-radius: 10px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
      position: relative;
      transform-style: preserve-3d;
      transform: rotateY(5deg) rotateX(-5deg);
      transition: $transition;

      &:before {
        content: '';
        position: absolute;
        top: -5px;
        left: -5px;
        width: 10px;
        height: 10px;
        background: #ff9800;
        border-radius: 50%;
        z-index: 1;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid $accent-yellow;
        transform: translateX(-50%);
        z-index: -1;
      }

      h2 {
        font-size: 1.5rem;
        font-weight: 600;
        color: $background-dark;
        margin-bottom: 1rem;
      }

      p {
        font-size: 1rem;
        line-height: 1.6;
        color: $background-dark;
        opacity: 0.9;
      }

      &.note-1 {
        transform: rotateY(5deg) rotateX(-5deg) translateZ(20px);
        animation: float 3s ease-in-out infinite;
      }

      &.note-2 {
        transform: rotateY(-5deg) rotateX(5deg) translateZ(10px);
        animation: float 3s ease-in-out infinite 0.5s;
      }

      &.note-3 {
        transform: rotateY(0deg) rotateX(0deg) translateZ(30px);
        animation: float 3s ease-in-out infinite 1s;
      }
    }
  }

  // 3D hover effect
  .note:hover {
    transform: rotateY(0deg) rotateX(0deg) translateZ(40px) scale(1.05);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }
}

// Responsive adjustments
@media (max-width: 1024px) {
  .problem-section {
    padding: 2rem 1rem;

    h1 { font-size: 2rem; }
    .description { font-size: 1rem; }

    .sticky-notes {
      grid-template-columns: 1fr;
      gap: 1.5rem;

      .note {
        padding: 1rem;
      }
    }
  }

  @media (max-width: 768px) {
    .problem-section {
      h1 { font-size: 1.5rem; }
      .description { font-size: 0.875rem; }

      .sticky-notes {
        .note {
          padding: 0.75rem;
          h2 { font-size: 1.25rem; }
          p { font-size: 0.875rem; }
        }
      }
      .container {
        flex-direction: column;
        align-items: center; 
      }
    }
  }
}