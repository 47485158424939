// @import '../../styles/variables';
@import '../../variables.scss';
.cover-section {
    // min-height: 100vh;
    padding: 4rem 2rem;
    scroll-snap-align: start;
    position: relative;
    background: linear-gradient(135deg, #f8f9fa 0%, #1880e3 100%);
    overflow: hidden;
  
    .overlay {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      gap: 2rem;
  
      @media (max-width: 1024px) {
        flex-direction: column;
        text-align: center;
      }
    }
  
    .content-left {
      flex: 1;
      text-align: left;
      color: #2c3e50;
  
      h1 {
        font-size: 4rem;
        font-weight: 700;
        margin-bottom: 1rem;
        color: #3c70e7;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
        @media (max-width: 768px) {
          font-size: 2.5rem;
        }
      }
  
      .subtitle {
        font-size: 1.75rem;
        font-weight: 300;
        margin-bottom: 1.5rem;
        color: #2c3e50;
        margin-right: 1.8rem;
  
        .cursor {
          display: inline-block;
          animation: blink 0.7s infinite;
        }
  
        @media (max-width: 768px) {
          font-size: 1.25rem;
        }
      }
  
      .description {
        font-size: 1.125rem;
        line-height: 1.8;
        max-width: 40rem;
        margin-bottom: 2rem;
        // color: #7f8c8d;
        opacity: 0.9;
  
        @media (max-width: 768px) {
          font-size: 1rem;
          max-width: 100%;
        }
      }
  
      .cta {
        display: flex;
        gap: 1.5rem;
        justify-content: flex-start;
  
        @media (max-width: 768px) {
          justify-content: center;
          flex-direction: column;
        }
  
        .cta-button {
          background: #3c70e7;
          color: $white;
          padding: 0.75rem 2rem;
          border: none;
          border-radius: 2rem;
          font-size: 1.125rem;
          font-weight: 600;
          cursor: pointer;
          transition: $transition;
          box-shadow: 0 4px 12px rgb(60 112 231);
  
          &:hover {
            background: darken(#3c70e7, 10%);
            transform: scale(1.05);
          }
        }
  
        .play-video {
          background: transparent;
          color: #3c70e7;
          padding: 0.75rem 2rem;
          border: 2px solid #3c70e7;
          border-radius: 2rem;
          font-size: 1.125rem;
          font-weight: 600;
          cursor: pointer;
          transition: $transition;
  
          &:hover {
            background: #3c70e7;
            color: $white;
            transform: scale(1.05);
          }
        }
      }
    }
  
    .image-container {
      flex: 1;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 50rem;
  
      .user-image {
        width: 200px;
        height: 300px;
        object-fit: cover;
        border-radius: 15px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;
  
        &.left {
          transform: translateX(-20px);
        }
  
        &.right {
          transform: translateX(20px);
          width: auto;
          height: 246px;
          box-shadow: none;
        }
  
        &:hover {
          transform: scale(1.05) translateX(0);
        }
  
        @media (max-width: 1024px) {
          width: 150px;
          height: 250px;
        }
  
        @media (max-width: 768px) {
          display: none;
        }
      }
  
      .stat {
        position: absolute;
        background: rgba(255, 255, 255, 0.9);
        padding: 0.5rem 1rem;
        border-radius: 10px;
        font-size: 1rem;
        font-weight: 600;
        color: #3c70e7;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;
  
        &.left {
          top: 10px;
          left: -60px;
        }
  
        &.right {
          bottom: 10px;
          right: -60px;
        }
  
        @media (max-width: 1024px) {
          &.left { left: -40px; }
          &.right { right: -40px; }
        }
  
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  
    .background-shapes {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"%3E%3Cpath fill="%23e9ecef" fill-opacity="0.3" d="M0,160L48,176C96,192,192,224,288,224C384,224,480,192,576,186.7C672,181,768,203,864,208C960,213,1056,203,1152,186.7C1248,171,1344,149,1392,138.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"%3E%3C/path%3E%3C/svg%3E') no-repeat center;
      background-size: cover;
      opacity: 0.2;
    }
  
    @keyframes blink {
      0%, 100% { opacity: 1; }
      50% { opacity: 0; }
    }
  
    // Responsive adjustments
    @media (max-width: 1024px) {
      .cover-section {
        .content-left {
          h1 { font-size: 3rem; }
          .subtitle { font-size: 1.5rem; }
          .description { max-width: 35rem; }
        }
      }
    }
  
    @media (max-width: 768px) {
      .cover-section {
        padding: 2rem 1rem;
  
        .content-left {
          h1 { font-size: 2.5rem; }
          .subtitle { font-size: 1.25rem; }
          .description { font-size: 0.875rem; }
          .cta { flex-direction: column; gap: 1rem; }
        }
      }
    }
  }