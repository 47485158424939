@import '../../variables';
.business-model-section {
    // min-height: 100vh;
    padding: 4rem 2rem;
    scroll-snap-align: start;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #0d1b2a; // Dark background from the image
    position: relative;
  
    .container {
      max-width: 1200px;
      width: 100%;
      color: $white;
    }
  
    h1 {
      font-size: 2.5rem;
      font-weight: 700;
      margin-bottom: 1rem;
      text-align: center;
      color: $white;
    }
  
    .subheadline {
      font-size: 1.125rem;
      font-weight: 500;
      color: $secondary-color;
      text-align: center;
      margin-bottom: 2rem;
      text-transform: uppercase;
    }
  
    .content-grid {
      display: grid;
      grid-template-columns: 1fr 2fr 1fr;
      gap: 2rem;
      padding: 2rem 0;
      align-items: end;
  
      @media (max-width: 1024px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
      }
  
      .requirements, .customer {
        display: flex;
        flex-direction: column;
        gap: 1rem;
  
        .item {
          background: rgba($white, 0.1);
          padding: 1rem;
          border-radius: 5px;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
          transition: $transition;
  
          &:hover {
            background: rgba($white, 0.2);
            transform: translateY(-5px);
          }
  
          .number {
            font-size: 1.6rem;
            font-weight: 700;
            color: $background-blue;
            margin-right: 0.5rem;
          }
  
          p {
            font-size: 1rem;
            line-height: 1.6;
            color: $white;
            margin: 0;
          }
        }
      }
  
      .chart-container {
        width: 100%;
        height: 400px; // Adjust height as needed
        grid-column: span 2; // Span across two columns to match the diagram's position
  
        @media (max-width: 1024px) {
          grid-column: span 1;
          height: 300px;
        }
      }
  
      .diagram {
        .circle {
          position: relative;
          width: 300px;
          height: 300px;
          background: $background-blue;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  
          span {
            font-size: 1.25rem;
            font-weight: 600;
            color: $white;
            z-index: 2;
          }
  
          .segments {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
  
            .segment {
              position: absolute;
              width: 100px;
              height: 100px;
              background: rgba($white, 0.1);
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 0.875rem;
              color: $white;
              box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
              transition: $transition;
  
              &:hover {
                transform: scale(1.1);
                background: rgba($white, 0.2);
              }
  
              &:nth-child(1) { transform: rotate(0deg) translateX(100px); }
              &:nth-child(2) { transform: rotate(90deg) translateX(100px); }
              &:nth-child(3) { transform: rotate(180deg) translateX(100px); }
              &:nth-child(4) { transform: rotate(270deg) translateX(100px); }
            }
          }
        }
  
        @media (max-width: 1024px) {
          .circle { width: 200px; height: 200px; }
  
          .segments .segment {
            width: 70px;
            height: 70px;
            font-size: 0.75rem;
          }
        }
      }
    }
  
    .labels {
      display: flex;
      justify-content: space-between;
      padding: 1rem 0;
      color: $white;
      font-size: 1rem;
      font-weight: 500;
      border-top: 2px solid $background-blue;
      margin-top: 2rem;
  
      @media (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
      }
    }
  }
  
  // Responsive adjustments
  @media (max-width: 1024px) {
    .business-model-section {
      padding: 2rem 1rem;
  
      h1 { font-size: 2rem; }
      .subheadline { font-size: 1rem; }
  
      .content-grid { padding: 1rem 0; }
    }
  }
  
  @media (max-width: 768px) {
    .business-model-section {
      padding: 1rem;
  
      h1 { font-size: 1.5rem; }
      .subheadline { font-size: 0.875rem; }
  
      .content-grid {
        .requirements, .customer { gap: 0.5rem; }
  
        .item {
          padding: 0.75rem;
          .number { font-size: 1.25rem; }
          p { font-size: 0.875rem; }
        }
  
        .chart-container { height: 250px; }
      }
    }
  }