.contact-us {
  padding: 50px 100px;
  background: linear-gradient(135deg, #f0f8ff, #e6f5ff); // Light gradient for elegance
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 30px 20px;
  }

  .contact-us__heading {
    font-size: 2.5rem;
    font-weight: 700;
    color: #2d3748;
    margin-bottom: 1rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  .contact-us__subheading {
    font-size: 1.2rem;
    color: #4a5568;
    margin-bottom: 3rem;
    text-align: center;
    font-weight: 500;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 768px) {
      font-size: 1rem;
      margin-bottom: 2rem;
    }
  }

  .contact-us__content {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    padding: 2rem;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    // backdrop-filter: blur(5px); // Glassmorphism effect

    @media (max-width: 855px) {
      flex-direction: column;
      padding: 1.5rem;
    }

    .contact-us__info,
    .contact-us__form {
      flex: 1;
      padding: 2rem;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.15);
      // backdrop-filter: blur(10px);
      border: 1px solid rgba(50, 160, 239, 0.2);
      transition: 0.3s;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 10px 25px rgba(50, 160, 239, 0.3);
      }

      h3 {
        font-size: 1.75rem;
        font-weight: 600;
        color: #2d3748;
        margin-bottom: 1.5rem;
        text-align: center;
      }

      .info-subtitle {
        font-size: 1rem;
        color: #4a5568;
        margin-bottom: 1.5rem;
        font-style: italic;
      }
    }

    .contact-us__details {
      margin-top: 1.5rem;

      .contact-us__detail-item {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
        padding: 1rem;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 8px;
        transition: 0.3s;

        &:hover {
          background: rgba(255, 255, 255, 0.3);
        }

        .icon {
          font-size: 1.8rem;
          color: #32a0ef;
          margin-right: 1rem;
        }

        p {
          margin: 0;
          font-size: 1rem;
          color: #2d3748;
          line-height: 1.5;

          a {
            color: #32a0ef;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .contact-us__social {
      margin-top: 2rem;
      text-align: center;

      p {
        font-size: 1rem;
        color: #4a5568;
        margin-bottom: 1rem;
      }

      a {
        color: #0073b1;
        font-size: 1.5rem;
        margin: 0 0.5rem;
        transition: 0.3s;

        &:hover {
          color: #005f8d;
          transform: scale(1.2);
        }
      }
    }

    .contact-us__form {
      h3 {
        text-align: left;
      }

      form {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .contact-us__form-row {
          display: flex;
          gap: 1.5rem;

          @media (max-width: 768px) {
            flex-direction: column;
          }

          .contact-us__form-group {
            flex: 1;
            display: flex;
            flex-direction: column;

            label {
              font-size: 1rem;
              color: #2d3748;
              margin-bottom: 0.5rem;
              font-weight: 500;
              text-align: left;
            }

            input,
            textarea {
              padding: 0.75rem;
              border: 1px solid rgba(50, 160, 239, 0.3);
              border-radius: 5px;
              background: rgba(255, 255, 255, 0.2);
              color: #2d3748;
              font-family: Plus Jakarta Sans;
              outline: none;
              transition: 0.3s;

              &:focus {
                border-color: #32a0ef;
                box-shadow: 0 0 5px rgba(50, 160, 239, 0.5);
              }

              &::placeholder {
                color: #4a5568;
              }
            }

            textarea {
              resize: vertical;
              min-height: 120px;
            }
          }

          .checkbox-group {
            flex-direction: row;
            align-items: center;
            gap: 0.5rem;

            input[type="checkbox"] {
              margin: 0;
              cursor: pointer;
            }

            label {
              margin: 0;
              font-size: 0.9rem;
              color: #4a5568;
              text-align: left;

              a {
                color: #32a0ef;
                text-decoration: none;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }

        .contact-us__form-buttons {
          display: flex;
          justify-content: flex-end;
          gap: 1rem;
          margin-top: 1rem;

          .btn-cancel,
          .btn-submit {
            padding: 0.75rem 1.5rem;
            border: none;
            border-radius: 5px;
            font-size: 1rem;
            font-weight: 600;
            cursor: pointer;
            transition: 0.3s;
          }

          .btn-cancel {
            background: #e0e0e0;
            color: #2d3748;

            &:hover {
              background: #c0c0c0;
            }
          }

          .btn-submit {
            background: #32a0ef;
            color: #ffffff;

            &:hover {
              background: #005f8d;
            }
          }
        }
      }
    }
  }
}

// Responsive adjustments
@media (max-width: 1024px) {
  .contact-us {
    padding: 2rem 1rem;

    .contact-us__heading { font-size: 2rem; }
    .contact-us__subheading { font-size: 1rem; }

    .contact-us__content { padding: 1.5rem; }
  }
}

@media (max-width: 768px) {
  .contact-us {
    padding: 1rem;

    .contact-us__heading { font-size: 1.5rem; }
    .contact-us__subheading { font-size: 0.875rem; margin-bottom: 1.5rem; }

    .contact-us__content {
      .contact-us__info,
      .contact-us__form {
        padding: 1rem;
      }

      .contact-us__details .contact-us__detail-item { padding: 0.75rem; }

      .contact-us__form .contact-us__form-row { gap: 1rem; }

      .contact-us__form-group input,
      .contact-us__form-group textarea { font-size: 0.9rem; }

      .contact-us__form-buttons .btn-cancel,
      .contact-us__form-buttons .btn-submit { font-size: 0.9rem; padding: 0.5rem 1rem; }
    }
  }
}