@import '../../variables';
.audience-section {
    // min-height: 100vh;
    padding: 4rem 2rem;
    scroll-snap-align: start;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #11183c; // Darker blue-gray from the image
    position: relative;
  
    .container {
      max-width: 1200px;
      width: 100%;
      color: $white;
    }
  
    h1 {
      font-size: 2.5rem;
      font-weight: 700;
      margin-bottom: 2rem;
      text-align: center;
      position: relative;
      color: $white;
  
      &::after {
        content: '';
        position: absolute;
        bottom: -0.5rem;
        left: 50%;
        transform: translateX(-50%);
        width: 50px;
        height: 2px;
        background: $accent-green;
      }
    }
  
    .content-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
      padding: 2rem;
      background: #11183c;
  
      @media (max-width: 1024px) {
        grid-template-columns: 1fr;
      }
  
      .text-content {
        p {
          font-size: 1.125rem;
          line-height: 1.6;
          color: #9e9e9e;
          margin-bottom: 1.5rem;
          opacity: 0.9;
        }
  
        .points-list {
          list-style: none;
          padding: 0;
          margin: 0;
          height: 100%;
    display: flex
;
    flex-direction: column;
    justify-content: space-between;
  
          li {
            font-size: 1rem;
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            color: $white;
            text-align: left;
  
            .icon {
              flex-shrink: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 60px;
              height: 60px;
              background: linear-gradient(0deg, rgba(67,111,75,1) 0%, rgba(130,206,143,1) 100%);
              border-radius: 50%;
              margin-top: 0.2rem;
              margin-right: 15px;
  
              svg {
                width: 16px;
                height: 16px;
              }
            }
            .side-section-title {
                .title {
                    font-weight: bold;
                    margin-bottom: 10px;
                    font-size: 1.2rem;
                }
                .subtitle {
                    color: grey;
                }
            }
          }
        }
      }
  
      .image-content {
        .label-content {
          text-align: left;
        }
        .audience-image {
          width: 100%;
          height: 300px;
          object-fit: cover;
          border-radius: 0.5rem;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          transition: $transition;
  
          &:hover {
            transform: scale(1.05);
          }
        }
  
        @media (max-width: 1024px) {
          .audience-image { height: 200px; }
        }
      }
    }
  }
  
  // Responsive adjustments
  @media (max-width: 1024px) {
    .audience-section {
      .container { max-width: 100%; }
  
      h1 { font-size: 2rem; }
  
      .content-grid { padding: 1rem; }
    }
  }
  
  @media (max-width: 768px) {
    .audience-section {
      padding: 2rem 1rem;
  
      h1 { font-size: 1.5rem; }
  
      .content-grid {
        .text-content {
          p { font-size: 1rem; }
          .points-list li { font-size: 0.875rem; }
        }
        .image-content { display: none; } // Hide image on mobile for simplicity
      }
    }
  }